<template>
  <v-app>
    <div>
      <form class="form" @submit.stop.prevent="formOnsubmit()">
        <!--begin: Wizard Step 1-->
        <div
          class="pb-5"
          data-wizard-type="step-content"
          data-wizard-state="current"
        >
          <h5 class="font-weight-bolder text-dark font-size-h6 font-size-h3-lg">
            <span>
              {{ title }}
            </span>
          </h5>

          <MainForm :form="form" :purpose="purpose">
            <template v-slot:main>
              <!-- <b-form-group
                id="input-group-activity_type_id"
                label="Jenis Kegiatan: :"
                label-for="input-activity_type_id"
              >
                <b-form-select
                  id="input-activity_type_id"
                  v-model="form.activity_type_id"
                  :options="activities"
                  size="sm"
                  class="mt-3"
                ></b-form-select>
                <small class="text-danger">{{ error.activity_type_id }}</small>
              </b-form-group> -->

              <b-form-group
                id="input-group-activity_type_id"
                label="Jenis Kegiatan:"
                label-for="input-activity_type_id"
              >
                <treeselect
                  v-model="form.activity_type_id"
                  :multiple="false"
                  placeholder="Pilih Jenis Kegiatan"
                  :options="activities"
                />
                <small class="text-danger">{{ error.activity_type_id }}</small>
              </b-form-group>

              <!-- <b-form-group id="input-group-activity_name">
                <label for="input-activity_name">Nama Kegiatan:</label>
                <b-form-textarea
                  id="input-activity_name"
                  v-model="form.activity_name"
                  placeholder="Nama Kegiatan"
                  rows="4"
                  max-rows="8"
                ></b-form-textarea>
                <small class="text-danger">{{ error.activity_name }}</small>
              </b-form-group> -->

              <!-- dosen 1 -->
              <!-- <b-form-group id="input-group-teacher1">
                <label for="input-teacher1">Dosen Pembimbing I:</label>
                <b-form-input
                  id="input-teacher1"
                  v-model="form.teacher1"
                  placeholder="Dosen Pembimbing I"
                ></b-form-input>
                <small class="text-danger">{{ error.teacher1 }}</small>
              </b-form-group> -->

              <b-form-group
                id="input-group-is_external1"
                label="Dosen I internal / Eksternal:"
                label-for="input-is_external1"
              >
                <treeselect
                  v-model="form.is_external1"
                  :multiple="false"
                  placeholder="Dosen internal"
                  :options="is_external1"
                  @select="Is_external1"
                  @input="setIs_external1"
                />
                <small class="text-danger">{{ error.is_external1 }}</small>
              </b-form-group>

              <b-form-group
                v-if="show1"
                id="input-group-teacher1"
                label="Dosen Pembimbing I Internal Prodi:"
                label-for="input-teacher1"
              >
                <treeselect
                  v-model="form.teacher1"
                  :multiple="false"
                  placeholder="Pilih Dosen Pembimbing I Internal"
                  :options="teachers1"
                  @select="setTeacher1"
                  @input="setDefault1"
                />
                <small class="text-danger">{{ error.teacher1 }}</small>
              </b-form-group>

              <b-form-group id="input-group-teacher1_name">
                <label for="input-teacher1_name">Nama:</label>
                <b-form-input
                  id="input-teacher1_name"
                  v-model="form.teacher1_name"
                  placeholder="Nama"
                ></b-form-input>
                <small class="text-danger">{{ error.teacher1_name }}</small>
              </b-form-group>

              <b-form-group id="input-group-teacher1_registration_number">
                <label for="input-teacher1_registration_number">NIP:</label>
                <b-form-input
                  id="input-teacher1_registration_number"
                  v-model="form.teacher1_registration_number"
                  placeholder="NIP"
                ></b-form-input>
                <small class="text-danger">{{
                  error.teacher1_registration_number
                }}</small>
              </b-form-group>

              <b-form-group id="input-group-teacher1_origin_institution">
                <label for="input-teacher1_origin_institution"
                  >Instansi Asal:</label
                >
                <b-form-textarea
                  id="input-teacher1_origin_institution"
                  v-model="form.teacher1_origin_institution"
                  placeholder="Instansi Asal"
                  rows="4"
                  max-rows="8"
                ></b-form-textarea>
                <small class="text-danger">{{
                  error.teacher1_origin_institution
                }}</small>
              </b-form-group>

              <b-form-group id="input-group-teacher1_address_institution">
                <label for="input-teacher1_address_institution"
                  >Alamat Instansi:</label
                >
                <b-form-textarea
                  id="input-teacher1_address_institution"
                  v-model="form.teacher1_address_institution"
                  placeholder="Alamat Instansi"
                  rows="4"
                  max-rows="8"
                ></b-form-textarea>
                <small class="text-danger">{{
                  error.teacher1_address_institution
                }}</small>
              </b-form-group>

              <b-form-group id="input-group-teacher1_phone">
                <label for="input-teacher1_phone">No telphone:</label>
                <b-form-input
                  id="input-teacher1_phone"
                  v-model="form.teacher1_phone"
                  placeholder="No telphone"
                ></b-form-input>
                <small class="text-danger">{{ error.teacher1_phone }}</small>
              </b-form-group>

              <b-form-group id="input-group-teacher1_email">
                <label for="input-teacher1_email">Email:</label>
                <b-form-input
                  id="input-teacher1_email"
                  v-model="form.teacher1_email"
                  placeholder="Email"
                ></b-form-input>
                <small class="col text-danger">{{
                  error.teacher1_email
                }}</small>
              </b-form-group>

              <!-- dosen 2 -->
              <!-- <b-form-group id="input-group-teacher2">
                <label for="input-teacher2">Dosen Pembimbing II:</label>
                <b-form-input
                  id="input-teacher2"
                  v-model="form.teacher2"
                  placeholder="Dosen Pembimbing II"
                ></b-form-input>
                <small class="text-danger">{{ error.teacher2 }}</small>
              </b-form-group> -->

              <b-form-group
                id="input-group-is_external2"
                label="Dosen II internal / Eksternal:"
                label-for="input-is_external2"
              >
                <treeselect
                  v-model="form.is_external2"
                  :multiple="false"
                  placeholder="Dosen internal"
                  :options="is_external2"
                  @select="Is_external2"
                  @input="setIs_external2"
                />
                <small class="text-danger">{{ error.is_external2 }}</small>
              </b-form-group>

              <b-form-group
                v-if="show2"
                id="input-group-teacher2"
                label="Dosen Pembimbing II Internal Prodi:"
                label-for="input-teacher2"
              >
                <treeselect
                  v-model="form.teacher2"
                  :multiple="false"
                  placeholder="Pilih Dosen Pembimbing II Internal"
                  :options="teachers2"
                  @select="setTeacher2"
                  @input="setDefault2"
                />
                <small class="text-danger">{{ error.teacher2 }}</small>
              </b-form-group>

              <b-form-group id="input-group-teacher2_name">
                <label for="input-teacher2_name">Nama:</label>
                <b-form-input
                  id="input-teacher2_name"
                  v-model="form.teacher2_name"
                  placeholder="Nama"
                ></b-form-input>
                <small class="text-danger">{{ error.teacher2_name }}</small>
              </b-form-group>

              <b-form-group id="input-group-teacher2_registration_number">
                <label for="input-teacher2_registration_number">NIP:</label>
                <b-form-input
                  id="input-teacher2_registration_number"
                  v-model="form.teacher2_registration_number"
                  placeholder="NIP"
                ></b-form-input>
                <small class="text-danger">{{
                  error.teacher2_registration_number
                }}</small>
              </b-form-group>

              <b-form-group id="input-group-teacher2_origin_institution">
                <label for="input-teacher2_origin_institution"
                  >Instansi Asal:</label
                >
                <b-form-textarea
                  id="input-teacher2_origin_institution"
                  v-model="form.teacher2_origin_institution"
                  placeholder="Instansi Asal"
                  rows="4"
                  max-rows="8"
                ></b-form-textarea>
                <small class="text-danger">{{
                  error.teacher2_origin_institution
                }}</small>
              </b-form-group>

              <b-form-group id="input-group-teacher2_address_institution">
                <label for="input-teacher2_address_institution"
                  >Alamat Instansi:</label
                >
                <b-form-textarea
                  id="input-teacher2_address_institution"
                  v-model="form.teacher2_address_institution"
                  placeholder="Alamat Instansi"
                  rows="4"
                  max-rows="8"
                ></b-form-textarea>
                <small class="text-danger">{{
                  error.teacher2_address_institution
                }}</small>
              </b-form-group>

              <b-form-group id="input-group-teacher2_phone">
                <label for="input-teacher2_phone">No telphone:</label>
                <b-form-input
                  id="input-teacher2_phone"
                  v-model="form.teacher2_phone"
                  placeholder="No telphone"
                ></b-form-input>
                <small class="text-danger">{{ error.teacher2_phone }}</small>
              </b-form-group>

              <b-form-group id="input-group-teacher2_email">
                <label for="input-teacher2_email">Email:</label>
                <b-form-input
                  id="input-teacher2_email"
                  v-model="form.teacher2_email"
                  placeholder="Email"
                ></b-form-input>
                <small class="text-danger">{{ error.teacher2_email }}</small>
              </b-form-group>
            </template>
          </MainForm>
        </div>
        <!--end: Wizard Step 1-->

        <!--begin: Wizard Actions -->
        <div class="d-flex justify-content-between border-top pt-10">
          <div>
            <b-button type="submit" variant="primary">Simpan</b-button>
            <b-button
              type="button"
              class="ml-2"
              variant="default"
              @click="$router.push('/letters/activity-guidance-teacher/list')"
            >
              Batal
            </b-button>
          </div>
        </div>
        <!--end: Wizard Actions -->
      </form>
    </div>
  </v-app>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import MainForm from "@/view/components/letters/Form.vue";

export default {
  props: {
    form: Object,
    route: String,
    title: String,
    purpose: String,
    currentPhoto: String,
  },
  components: {
    MainForm,
  },
  data() {
    return {
      formData: new FormData(),
      show1: true,
      show2: true,
      is_external1: [
        {
          id: 0,
          value: 0,
          label: "Dosen Internal",
        },
        {
          id: 1,
          value: 1,
          label: "Dosen Eksternal",
        },
      ],
      is_external2: [
        {
          id: 0,
          value: 0,
          label: "Dosen Internal",
        },
        {
          id: 1,
          value: 1,
          label: "Dosen Eksternal",
        },
      ],
      activities: [
        {
          id: "",
          label: "Pilih Jenis Kegiatan",
          isDisabled: true,
        },
        {
          id: 1,
          value: 1,
          label: "Praktek Kerja Lapangan (PKL)",
        },
        {
          id: 2,
          value: 2,
          label: "Skripsi",
        },
        {
          id: 3,
          value: 3,
          label: "Praktek Mengajar",
        },
        {
          id: 4,
          value: 4,
          label: "Kuliah Kerja Lapangan (KKL)",
        },
        {
          id: 5,
          value: 5,
          label:
            "Pembina Kegiatan Kemahasiswaan Program Kreativitas Mahasiswa (PKM)",
        },
        {
          id: 6,
          value: 6,
          label: "Lain-lain: ...",
        },
      ],
      error: {
        semester_id: "",
        activity_type_id: "",
        activity_name: "",
        Is_external1: "",
        teacher1: "",
        teacher1_name: "",
        teacher1_registration_number: "",
        teacher1_origin_institution: "",
        teacher1_address_institution: "",
        teacher1_phone: "",
        teacher1_email: "",
        Is_external2: "",
        teacher2: "",
        teacher2_name: "",
        teacher2_registration_number: "",
        teacher2_origin_institution: "",
        teacher2_address_institution: "",
        teacher2_phone: "",
        teacher2_email: "",
        sign_image: "",
      },
      current_photo: "/img/default/blank.jpg",
      default_photo: "/img/default/blank.jpg",
      semesters: [],
      teachers1: [],
      teachers2: [],
    };
  },
  methods: {
    Is_external1(event) {
      console.log(event.value);
      if (event.value == 0) {
        this.show1 = true;
      } else {
        this.show1 = false;
      }
    },
    Is_external2(event) {
      console.log(event.value);
      if (event.value == 0) {
        this.show2 = true;
      } else {
        this.show2 = false;
      }
    },
    async getTeacherOption() {
      //ganti
      let response = await module.setTreeSelectActivity(
        "api/users",
        "?role_id=1"
      );
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.teachers1 = response.data;
        this.teachers1.unshift({
          label: "Pilih Dosen",
          id: "",
          isDisabled: true,
        });
        this.teachers2 = response.data;
        this.teachers2.unshift({});
      }
    },
    setTeacher1(evt) {
      this.form.teacher1_name = evt.name;
      this.form.teacher1_registration_number = evt.registration_number;
      this.form.teacher1_origin_institution =
        "Fisika FMIPA Universitas Negeri Jakarta";
      this.form.teacher1_address_institution =
        "Kampus A UNJ, Gedung Hasjim Asj’arie Jl Rawamangun Muka, Jakarta Timur 13220";
      this.form.teacher1_phone = evt.phone;
      this.form.teacher1_email = evt.email;
    },
    setTeacher2(evt) {
      this.form.teacher2_name = evt.name;
      this.form.teacher2_registration_number = evt.registration_number;
      this.form.teacher2_origin_institution =
        "Fisika FMIPA Universitas Negeri Jakarta";
      this.form.teacher2_address_institution =
        "Kampus A UNJ, Gedung Hasjim Asj’arie Jl Rawamangun Muka, Jakarta Timur 13220";
      this.form.teacher2_phone = evt.phone;
      this.form.teacher2_email = evt.email;
    },
    setDefault1(evt) {
      if (typeof evt == "undefined") {
        this.form.teacher1_name = "";
        this.form.teacher1_registration_number = "";
        this.form.teacher1_origin_institution = "";
        this.form.teacher1_address_institution = "";
        this.form.teacher1_phone = "";
        this.form.teacher1_email = "";
      }
    },
    setIs_external1(evt) {
      if (typeof evt == "undefined") {
        this.show1 = true;
      }
    },
    setIs_external2(evt) {
      if (typeof evt == "undefined") {
        this.show2 = true;
      }
    },
    setDefault2(evt) {
      if (typeof evt == "undefined") {
        this.form.teacher2_name = "";
        this.form.teacher2_registration_number = "";
        this.form.teacher2_origin_institution = "";
        this.form.teacher2_address_institution = "";
        this.form.teacher2_phone = "";
        this.form.teacher2_email = "";
      }
    },
    async getSemesterActive() {
      let response = await module.get("api/semesters/now");
      // If Data Not Found
      if (response != null) {
        this.form.semester_id = response.id;
      }
    },
    async getSemesterOption() {
      let response = await module.setTreeSelect("api/semesters");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.semesters = response.data;
        this.semesters.unshift({
          label: "Pilih Semester",
          id: "",
          isDisabled: true,
        });
      }
    },
    onFileChange(e) {
      const file = e.target.files[0];

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        this.form.sign_image = e.target.files[0];

        reader.onload = (event) => {
          this.current_photo = event.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    onRemove() {
      this.current_photo = null;
      this.form.photo = "";
    },
    validationCheck() {
      let status = true;
      // if (
      //   this.form.hasOwnProperty("interest") == false ||
      //   this.form.interest == ""
      // ) {
      //   this.error.interest = "Kolom kepeminatan tidak boleh kosong";
      //   status = false;
      // } else {
      //   this.error.interest = "";
      // }
      // if (
      //   this.form.hasOwnProperty("institution1") == false ||
      //   this.form.institution1 == ""
      // ) {
      //   this.error.institution1 = "Kolom instansi 1 tidak boleh kosong";
      //   status = false;
      // } else {
      //   this.error.institution1 = "";
      // }
      // if (
      //   this.form.hasOwnProperty("institution_address1") == false ||
      //   this.form.institution_address1 == ""
      // ) {
      //   this.error.institution_address1 =
      //     "Kolom alamat instansi 1 tidak boleh kosong";
      //   status = false;
      // } else {
      //   this.error.institution_address1 = "";
      // }
      // if (
      //   this.form.hasOwnProperty("institution2") == false ||
      //   this.form.institution2 == ""
      // ) {
      //   this.error.institution2 = "Kolom instansi 2 tidak boleh kosong";
      //   status = false;
      // } else {
      //   this.error.institution2 = "";
      // }
      // if (
      //   this.form.hasOwnProperty("institution_address2") == false ||
      //   this.form.institution_address2 == ""
      // ) {
      //   this.error.institution_address2 =
      //     "Kolom instansi 2 tidak boleh kosong";
      //   status = false;
      // } else {
      //   this.error.institution_address2 = "";
      // }
      // if (this.purpose == "add") {
      //   if (
      //     this.form.hasOwnProperty("sign_image") == false ||
      //     this.form.sign_image == ""
      //   ) {
      //     this.error.sign_image = "Gambat TTD belum dipilih";
      //     status = false;

      //     this.$root.$emit("validationImageTrue");
      //     console.log("send emit");
      //   } else {
      //     this.error.sign_image = "";
      //     this.$root.$emit("validationImageFalse");
      //   }
      // }

      return status;
    },
    async formOnsubmit() {
      if (this.validationCheck() == false) {
        Swal.fire({
          title: "Gagal",
          text: "Harap periksa kembali form",
          icon: "error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        throw new Error("Validation Error");
      }

      for (const [key, value] of Object.entries(this.form)) {
        this.formData.append(key, value);
      }

      // Make Request
      let response = await module.submit(this.formData, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire({
          title: response.success.title,
          text: response.success.message,
          icon: "success",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });

        this.$router.push("/letters/activity-guidance-teacher/list");
      }
    },
  },
  mounted() {
    this.getSemesterOption();
    this.getSemesterActive();
    this.getTeacherOption();
  },
  computed: {
    photo() {
      return this.current_photo == null
        ? this.default_photo
        : this.current_photo;
    },
  },
  watch: {
    form: function (newVal, oldVal) {
      if (this.purpose == "edit") {
        this.form = this.form;
        this.current_photo = this.form.sign;
        this.default_photo = this.form.sign;
      }
    },
  },
};
</script>

<style scoped>
.image-input-wrapper {
  width: 180px !important;
  height: 180px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>